@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Bebas Neue", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bebas-neue {
  font-family: "Bebas Neue", sans-serif;
}

.exo-2 {
  font-family: "Exo 2", sans-serif;
}

.cursor-pointer{
  cursor: pointer !important;
}

.fullscreen-poster {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.coming-soon-text {
  display: none;
  font-family: 'Warpen', sans-serif;
  font-size: 25px;
  color: #BFBFBF;
  position: absolute;
  bottom: 10vh;
  left: 50%;
  transform: translateX(-50%);
}

.social-icon-wrapper {
  position: relative;
}

.social-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.social-icon {
  transition: filter 0.3s ease;
}

.social-name {
  opacity: 0;
  margin-left: 10px;
  color: white;
  font-size: 16px;
  transition: opacity 0.3s ease;
}

.social-link:hover .social-icon {
  filter: brightness(0) saturate(3000%) invert(2);
}

.social-link:hover .social-name {
  opacity: 1;
}

.font-weight-500 {
  font-weight: 500;
}

.text-light-gray {
  color: #D9D9D9 !important;
}

.text-transparent {
  color: rgba(0, 0, 0, 0);
}

.smallest-text {
  font-size: 8px !important;
}

.contact-us-bg {
  background: url('assets/images/home/contact_us_bg_drop.png');
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  align-self: center;
}

.wishlist-bg {
  background: url('assets/images/home/contact_us_bg_drop.png');
  // background-color: linear-gradient(to right, #0b0b0b 10%, #131313 40%, #1b1b1b 100%);
  background-color: #000;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  align-self: center;
  z-index: 2;
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
    opacity: 1;
  }

  100% {
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
  }
}

.button-hover-interaction {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes backToNormal {
  0% {
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
  }

  50% {
    background: rgba(0, 0, 0, 0.7);
    opacity: 1;
  }

  100% {
    background: rgba(0, 0, 0, 0);
    opacity: 1;
  }
}

.button-clicked-interaction {
  animation: backToNormal 1s ease-in-out;
}

/* Mobile Background */
@media (max-width: 767px) {
  .fullscreen-poster {
    background-image: url('assets/coming_soon.gif');
    width: 100vw;
    background-size: contain;
    background-color: #000;
  }

  .coming-soon-text {
    display: block;
  }
}

@media (max-width: 576px) {
  .big-text {
    font-size: 17.73px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.1356em;
  }

  .medium-text {
    font-size: 10.73px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.1356em;
  }

  .small-text {
    font-size: 11.54px !important;
    letter-spacing: 0.1356em;
  }

  .navbar-header {
    font-size: 8.55px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.0425em;
  }
}

@media (min-width: 576px) {
  .big-text {
    font-size: 24px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.1356em;
  }

  .medium-text {
    font-size: 16px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.1356em;
  }

  .small-text {
    font-size: 12.32px !important;
    letter-spacing: 0.1356em;
  }

  .navbar-header {
    font-size: 12px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.0425em;
  }
}

/* Desktop Background */
@media (min-width: 768px) {

  .big-text {
    font-size: 39.5px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.1356em;
  }

  .medium-text {
    font-size: 20.38px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.1356em;
  }

  .small-text {
    font-size: 18.32px !important;
    letter-spacing: 0.1356em;
  }

  .navbar-header {
    font-size: 18px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.0425em;
  }

  .fullscreen-poster {
    background-image: url('assets/coming_soon.gif');
  }
}

@media (min-width: 1200px) {
  .big-text {
    font-size: 39.5px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.1356em;
  }

  .medium-text {
    font-size: 20.38px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.1356em;
  }

  .small-text {
    font-size: 18.32px !important;
    letter-spacing: 0.1356em;
  }

  .navbar-header {
    font-size: 18px !important;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.0425em;
  }
}